import 'js/polyfill';

import HomeView from 'js/home_v2/views/view';

// Create home view
new HomeView({
  trigger: window.TRIGGER,
  email: window.EMAIL,
  defaultUserData: window.DEFAULT_USER_DATA,
}).create();
