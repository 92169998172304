import ModalForm from 'js/base_v2/modal-form';

/**
 * Setup Password Form.
 *
 * @class
 * @extends ModalForm
 *
 * @param {object} options
 */
function SetupPasswordForm(options) {
  ModalForm.call(this, options, true);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.extendDefaultOptions({
      tmplEl: '#setup-password-form',
      tmplParams: {
        title: 'Create your password',
        submitBtnLabel: 'Set Up Account',
      },
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.afterSubmit = function(response) {
    if (false === parent.afterSubmit.call(this, response)) {
      return false;
    }

    return undefined;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      password: {
        required: {
          message: 'Please enter the password.',
        },
      },
    };
  };

  /**
   * @inheritDoc
   */
  this.getAfterSubmitMessage = function() {
    return 'Password successfully set up.';
  };

  // Initialize
  this.init();
}

export default SetupPasswordForm;
