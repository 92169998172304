import ModalForm from 'js/base_v2/modal-form';
import AddressSaveForm from 'js/address/save-form';
import SetupPasswordForm from 'js/auth/forms/setup-password-form';

/**
 * Setup Account Form.
 *
 * @class
 * @extends ModalForm
 *
 * @param {object} options
 */
function SetupAccountForm(options) {
  ModalForm.call(this, options, true);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {AddressSaveForm}
   */
  this.addressSaveForm = null;

  /**
   * @prop {SetupPasswordForm}
   */
  this.setupPasswordForm = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.extendDefaultOptions({
      tmplEl: '#setup-account-form',
      addressSaveForm: {
        tmplParams: {
          title: 'Set up your location',
          submitBtnLabel: 'Create Location',
        },
        actionUrl: '/auth/standard/setup-location',
        afterSubmit() {
          self.afterAccountSetup();
        },
        onHide() {
          self.afterAccountSetup();
        },
      },
      setupPasswordForm: {
        afterSubmit() {
          if (!self.options.defaults.skip_address) {
            self.createAddressSaveForm();
          } else {
            window.location.reload();
          }
        },
      },
      tmplParams: {
        title: 'Set up your account',
        submitBtnLabel: 'Set Up Account',
        submitBtnClass: 'disable',
      },
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    this.extendOptions({
      addressSaveForm: {
        defaultRecord: this.options.defaults.address,
      },
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.formEl.on('click', '.acceptAgreement', () => {
      this.onAcceptAgreementButtonClick();
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.afterSubmit = function(response) {
    if (false === parent.afterSubmit.call(this, response)) {
      return false;
    }

    if (1 !== +this.options.defaults.sso_enabled) {
      this.createSetupPasswordForm();
    }

    return undefined;
  };

  /**
   * Accept agreement button click event handler.
   */
  this.onAcceptAgreementButtonClick = function() {
    const checkedAgreements = $('.acceptAgreement:checked').length;

    $('button[type="submit"]', this.formEl).toggleClass(
      'disable',
      2 !== checkedAgreements,
    );
  };

  /**
   * After account setup event handler.
   */
  this.afterAccountSetup = function() {
    window.location = window.location.pathname;
  };

  /**
   * Create address save form.
   *
   * @return {HomeView}
   */
  this.createAddressSaveForm = function() {
    this.addressSaveForm = new AddressSaveForm(
      null,
      this.options.addressSaveForm,
    ).create();

    return this;
  };

  /**
   * Create setup password form.
   *
   * @return {HomeView}
   */
  this.createSetupPasswordForm = function() {
    this.setupPasswordForm = new SetupPasswordForm(
      this.options.setupPasswordForm,
    ).create();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      first_name: {
        required: {
          message: 'Please enter the first name.',
        },
      },
      last_name: {
        required: {
          message: 'Please enter the last name.',
        },
      },
      company: {
        required: {
          message: 'Please enter the company name.',
        },
      },
    };
  };

  /**
   * @inheritDoc
   */
  this.getAfterSubmitMessage = function() {
    return 'Account successfully set up.';
  };

  // Initialize
  this.init();
}

export default SetupAccountForm;
