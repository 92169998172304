import BaseDashboardView from 'js/base_v2/views/dashboard-view';
import SetupAccountForm from 'js/auth/forms/setup-account-form';
import SetupPasswordForm from 'js/auth/forms/setup-password-form';
import DefaultNotifier from 'js/components_v2/default-notifier';

/**
 * Home View.
 *
 * @class
 * @extends BaseDashboardView
 *
 * @param {object} [options]
 */
function HomeView(options) {
  BaseDashboardView.call(this, options);
  const parent = this.clone();

  /**
   * @prop {SetupAccountForm}
   */
  this.setupAccountForm = null;

  /**
   * @prop {SetupPasswordForm}
   */
  this.setupPasswordForm = null;

  /**
   * @prop {DefaultNotifier}
   */
  this.notifier = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      trigger: null,
      setupAccountForm: {
        afterSubmit: () => {
          if (_.isObject(this.options.defaultUserData) &&
            1 === +this.options.defaultUserData.sso_enabled
          ) {
            window.location.reload();
          }
        },
      },
      setupPasswordForm: {
        tmplParams: {
          title: 'Set up new password',
          submitBtnLabel: 'Submit',
        },
        afterSubmit: () => {
          window.location.reload();
        },
      },
      notifier: {},
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    return this.extendOptions({
      loginFormGroup: {
        defaults: this.options.defaultUserData,
      },
      createAccountForm: {
        defaults: this.options.defaultUserData,
      },
      setupAccountForm: {
        defaults: this.options.defaultUserData,
      },
    });
  };

  /**
   * Create setup account form.
   *
   * @return {HomeView}
   */
  this.createSetupAccountForm = function() {
    this.setupAccountForm = new SetupAccountForm(
      this.options.setupAccountForm.extend({
        tmplParams: {
          email: this.options.email,
        },
      }),
    ).create();

    return this;
  };

  /**
   * Create setup password form.
   *
   * @return {HomeView}
   */
  this.createSetupPasswordForm = function() {
    this.setupPasswordForm = new SetupPasswordForm(
      this.options.setupPasswordForm,
    ).create();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    this.notifier = new DefaultNotifier(this.options.notifier).create();

    if (this.options.trigger) {
      // Clean the url so that a refresh won't trigger the same action again
      window.history.replaceState(null, '', '/');
    }

    const urlTrigger = new RegExp('[?&]trigger=([^&#]*)')
      .exec(window.location.href);

    if ('access-denied' === this.options.trigger && _.isNull(urlTrigger)) {
      this.options.trigger = null;
    }

    switch (this.options.trigger) {
      case 'login':
        return this.createLoginFormGroup({
          defaults: {
            email: this.options.email || undefined,
          },
        });
      case 'create-account':
        return this.createCreateAccountForm();
      case 'setup-account':
        return this.createSetupAccountForm();
      case 'recover-password':
        return this.createSetupPasswordForm();
      case 'access-denied':
        this.notifier.notifyError(
          this.translator.get('error_access_denied'),
        );
        return this;
      default:
        return this;
    }
  };

  // Initialize
  this.init();
}

export default HomeView;
